@use '@angular/material' as mat;

// -----------------------------------------------------------------------------------------------------
// @ Body scroll lock
// -----------------------------------------------------------------------------------------------------
html,
body {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

// -----------------------------------------------------------------------------------------------------
// @ Boxed body
// -----------------------------------------------------------------------------------------------------
body {

    // Boxed
    &.boxed {
        max-width: 1200px;
        margin: 0 auto;

        @include mat.elevation(8);
    }
}

/*----------------------------------------------------------------*/
/*  @ Text rendering & box sizing
/*----------------------------------------------------------------*/
* {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:before, &:after {
        box-sizing: border-box;
    }

    // Remove focus outline
    &:focus {
        outline: none;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Responsive images
// -----------------------------------------------------------------------------------------------------

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}

// -----------------------------------------------------------------------------------------------------
// @ Input
// -----------------------------------------------------------------------------------------------------
input {
    border: none;
    padding: 0 8px;
}

// -----------------------------------------------------------------------------------------------------
// @ Avatar
// -----------------------------------------------------------------------------------------------------
.avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    font-size: 17px;
    font-weight: 600;
    text-align: center;

    &.square {
        border-radius: 0;
    }

    &.small {
        width: 20px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
    }

    &.big {
        width: 72px;
        min-width: 72px;
        height: 72px;
        line-height: 72px;
    }

    &.huge {
        width: 96px;
        min-width: 96px;
        height: 96px;
        line-height: 96px;
    }
}

.avatar-wrapper {
    position: relative;

    .avatar {
        margin-top: 0;
        margin-bottom: 0;
    }
    mat-icon.status {
        position: absolute;
        top: 28px;
        left: 28px;
    }
}
