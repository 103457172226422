/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
:root {
    font-family: "Inter var", sans-serif;
    font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    font-variation-settings: normal;
    --font-family: "Inter var", sans-serif;
    --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --focus-ring: 0 0 0 0.2rem rgba(var(--cdk-primary-300-rgb), 0.5);
    --maskbg: rgba(0, 0, 0, 0.4);
}
* {
    @apply box-border;
}
.p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    @apply text-base;
}
.p-component-overlay {
    @apply bg-[rgba(0,0,0,0.4)] duration-[0.2s];
}
.p-disabled,
.p-component:disabled {
    @apply opacity-40;
}
.p-error {
    @apply text-red-300;
}
.p-text-secondary {
    @apply text-secondary;
}
.pi {
    @apply text-base;
}
.p-icon {
    @apply w-4 h-4 text-primary;
}
.p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    @apply text-base rounded-md focus-visible:outline-offset-0 focus-visible:shadow-[0_0_0_0.2rem_rgba(165,180,252,0.5)];
}
.p-link:focus-visible {
    outline: 0 none;
}
.p-component-overlay-enter {
    @apply animate-[p-component-overlay-enter-animation_150ms_forwards];
}
.p-component-overlay-leave {
    @apply animate-[p-component-overlay-leave-animation_150ms_forwards];
}
@keyframes p-component-overlay-enter-animation {
    from {
        @apply bg-transparent;
    }
    to {
        @apply bg-[color:var(--maskbg)];
    }
}
@keyframes p-component-overlay-leave-animation {
    from {
        @apply bg-[color:var(--maskbg)];
    }
    to {
        @apply bg-transparent;
    }
}
.p-button {
    @apply text-primary-950 border border-primary-300 text-base px-5 py-3 rounded-md border-solid enabled:hover:text-primary-950 enabled:hover:border-primary-400 enabled:active:text-primary-950 enabled:active:border-primary-500 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(165,180,252,0.5)];
    @apply bg-primary-300;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}
.p-button:enabled:hover {
    @apply bg-default border-transparent;
}
.p-button:enabled:active {
    @apply bg-primary-400;
}
.p-button.p-button-outlined {
    @apply bg-transparent text-primary-300 border border-solid enabled:hover:text-primary-300 enabled:hover:border enabled:hover:border-solid enabled:active:text-primary-300 enabled:active:border enabled:active:border-solid;
}
.p-button.p-button-outlined:enabled:hover {
    @apply: bg-primary-300 opacity-4;
}
.p-button.p-button-outlined:enabled:active {
    @apply bg-primary-300 opacity-16;
}
.p-button.p-button-outlined.p-button-plain {
    @apply text-[rgba(255,255,255,0.60)] border-[rgba(255,255,255,0.60)] enabled:hover:text-[rgba(255,255,255,0.60)] enabled:active:text-[rgba(255,255,255,0.60)];
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
    @apply bg-opacity-4;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
    background: rgba(255, 255, 255, 0.16);
}
.p-button.p-button-text {
    @apply bg-transparent text-primary-300 border-transparent enabled:hover:text-primary-300 enabled:hover:border-transparent enabled:active:text-primary-300 enabled:active:border-transparent;
}
.p-button.p-button-text:enabled:hover {
    background: rgba(165, 180, 252, 0.04);
}
.p-button.p-button-text:enabled:active {
    @apply bg-primary-300 bg-opacity-16;
}
.p-button.p-button-text.p-button-plain {
    @apply text-[rgba(255,255,255,0.60)] enabled:hover:text-[rgba(255,255,255,0.60)] enabled:active:text-[rgba(255,255,255,0.60)];
}
.p-button.p-button-text.p-button-plain:enabled:hover {
    @apply bg-opacity-4;
}
.p-button.p-button-text.p-button-plain:enabled:active {
    background: rgba(255, 255, 255, 0.16);
}
.p-button:focus {
    outline: 0 none;
}
.p-button .p-button-label {
    @apply duration-[0.2s];
}
.p-button .p-button-icon-left {
    @apply mr-2;
}
.p-button .p-button-icon-right {
    @apply ml-2;
}
.p-button .p-button-icon-bottom {
    @apply mt-2;
}
.p-button .p-button-icon-top {
    @apply mb-2;
}
.p-button .p-badge {
    @apply min-w-[1rem] h-4 leading-4 text-primary-300 bg-primary-950 ml-2;
}
.p-button.p-button-raised {
    @apply shadow-[0px_3px_1px_-2px_rgba(0,0,0,0.2),0px_2px_2px_0px_rgba(0,0,0,0.14),0px_1px_5px_0px_rgba(0,0,0,0.12)];
}
.p-button.p-button-rounded {
    @apply rounded-[2rem];
}
.p-button.p-button-icon-only {
    @apply w-12 px-0 py-3;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    @apply m-0;
}
.p-button.p-button-icon-only.p-button-rounded {
    @apply h-12 rounded-[50%];
}
.p-button.p-button-sm {
    @apply text-sm px-[1.09375rem] py-[0.65625rem];
}
.p-button.p-button-sm .p-button-icon {
    @apply text-sm;
}
.p-button.p-button-lg {
    @apply text-xl px-[1.5625rem] py-[0.9375rem];
}
.p-button.p-button-lg .p-button-icon {
    @apply text-xl;
}
.p-button.p-button-loading-label-only .p-button-label {
    @apply ml-2;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
    @apply mr-0;
}
.p-fluid .p-button {
    @apply w-full;
}
.p-fluid .p-button-icon-only {
    @apply w-12;
}
.p-fluid .p-buttonset {
    @apply flex;
}
.p-fluid .p-buttonset .p-button {
    @apply flex-1;
}
.p-splitbutton {
    display: inline-flex;
    position: relative;
}

.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
    flex: 1 1 auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-splitbutton .p-menu {
    min-width: 100%;
}

.p-fluid .p-splitbutton {
    display: flex;
}
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
    @apply text-primary-950 border border-slate-300 border-solid;
    background: #cbd5e1;
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    @apply text-primary-950 border-[#b0bfd1];
    background: #b0bfd1;
}
.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    @apply shadow-[0_0_0_1px_#dbe2ea];
}
.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
    @apply text-primary-950 border-[#95a9c2];
    background: #95a9c2;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    @apply bg-transparent text-slate-300 border border-solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    @apply text-slate-300 border border-solid;
    background: rgba(203, 213, 225, 0.04);
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    @apply text-slate-300 border border-solid;
    background: rgba(203, 213, 225, 0.16);
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
    @apply bg-transparent text-slate-300 border-transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    @apply text-slate-300 border-transparent;
    background: rgba(203, 213, 225, 0.04);
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    @apply text-slate-300 border-transparent;
    background: rgba(203, 213, 225, 0.16);
}
.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
    @apply text-primary-950 border border-blue-300 border-solid;
    background: #93c5fd;
}
.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
    @apply text-primary-950 border-[#6cb0fc];
    background: #6cb0fc;
}
.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
    @apply shadow-[0_0_0_1px_#b3d6fe];
}
.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
    @apply text-primary-950 border-[#449bfc];
    background: #449bfc;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
    @apply bg-transparent text-blue-300 border border-solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    @apply text-blue-300 border border-solid;
    background: rgba(147, 197, 253, 0.04);
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    @apply text-blue-300 border border-solid;
    background: rgba(147, 197, 253, 0.16);
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
    @apply bg-transparent text-blue-300 border-transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    @apply text-blue-300 border-transparent;
    background: rgba(147, 197, 253, 0.04);
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    @apply text-blue-300 border-transparent;
    background: rgba(147, 197, 253, 0.16);
}
.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    @apply text-primary-950 border border-green-300 border-solid;
    background: #86efac;
}
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
    @apply text-primary-950 border-[#65eb95];
    background: #65eb95;
}
.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
    @apply shadow-[0_0_0_1px_#aaf4c5];
}
.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
    @apply text-primary-950 border-[#44e67f];
    background: #44e67f;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
    @apply bg-transparent text-green-300 border border-solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    @apply text-green-300 border border-solid;
    background: rgba(134, 239, 172, 0.04);
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    @apply text-green-300 border border-solid;
    background: rgba(134, 239, 172, 0.16);
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
    @apply bg-transparent text-green-300 border-transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    @apply text-green-300 border-transparent;
    background: rgba(134, 239, 172, 0.04);
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    @apply text-green-300 border-transparent;
    background: rgba(134, 239, 172, 0.16);
}
.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
    @apply text-primary-950 border border-amber-300 border-solid;
    background: #fcd34d;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
    @apply text-primary-950 border-[#fbcb2d];
    background: #fbcb2d;
}
.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
    @apply shadow-[0_0_0_1px_#fde082];
}
.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
    @apply text-primary-950 border-[#fbc30c];
    background: #fbc30c;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    @apply bg-transparent text-amber-300 border border-solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    @apply text-amber-300 border border-solid;
    background: rgba(252, 211, 77, 0.04);
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    @apply text-amber-300 border border-solid;
    background: rgba(252, 211, 77, 0.16);
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
    @apply bg-transparent text-amber-300 border-transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    @apply text-amber-300 border-transparent;
    background: rgba(252, 211, 77, 0.04);
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    @apply text-amber-300 border-transparent;
    background: rgba(252, 211, 77, 0.16);
}
.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
    @apply text-primary-950 border border-purple-300 border-solid;
    background: #d8b4fe;
}
.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
    @apply text-primary-950 border-[#c289fd];
    background: #c289fd;
}
.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
    @apply shadow-[0_0_0_1px_#e4cbfe];
}
.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
    @apply text-primary-950 border-[#ab5efd];
    background: #ab5efd;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
    @apply bg-transparent text-purple-300 border border-solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    @apply text-purple-300 border border-solid;
    background: rgba(216, 180, 254, 0.04);
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    @apply text-purple-300 border border-solid;
    background: rgba(216, 180, 254, 0.16);
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
    @apply bg-transparent text-purple-300 border-transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    @apply text-purple-300 border-transparent;
    background: rgba(216, 180, 254, 0.04);
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    @apply text-purple-300 border-transparent;
    background: rgba(216, 180, 254, 0.16);
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
    @apply text-primary-950 border border-red-300 border-solid;
    background: #fca5a5;
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
    @apply text-primary-950 border-[#fb7d7d];
    background: #fb7d7d;
}
.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
    @apply shadow-[0_0_0_1px_#fdc0c0];
}
.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
    @apply text-primary-950 border-[#f95454];
    background: #f95454;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    @apply bg-transparent text-red-300 border border-solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    @apply text-red-300 border border-solid;
    background: rgba(252, 165, 165, 0.04);
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    @apply text-red-300 border border-solid;
    background: rgba(252, 165, 165, 0.16);
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
    @apply bg-transparent text-red-300 border-transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    @apply text-red-300 border-transparent;
    background: rgba(252, 165, 165, 0.04);
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    @apply text-red-300 border-transparent;
    background: rgba(252, 165, 165, 0.16);
}
.p-button.p-button-link {
    @apply text-primary-300 border-[transparent] enabled:hover:text-primary-300 enabled:hover:border-transparent enabled:focus:shadow-[0_0_0_1px_rgba(165,180,252,0.5)] enabled:focus:border-transparent enabled:active:text-primary-300 enabled:active:border-transparent;
    background: transparent;
}
.p-button.p-button-link:enabled:hover {
    background: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
    @apply underline;
}
.p-button.p-button-link:enabled:focus {
    background: transparent;
}
.p-button.p-button-link:enabled:active {
    background: transparent;
}
.p-splitbutton {
    @apply rounded-md;
}
.p-splitbutton.p-button-outlined > .p-button {
    @apply bg-transparent text-primary-300 border border-solid;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-primary-300;
    background: rgba(165, 180, 252, 0.04);
}
.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-primary-300;
    @apply bg-primary-300 bg-opacity-16;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    @apply text-[rgba(255,255,255,0.60)] border-[rgba(255,255,255,0.60)];
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-[rgba(255,255,255,0.60)];
    @apply bg-opacity-4;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-[rgba(255,255,255,0.60)];
    background: rgba(255, 255, 255, 0.16);
}
.p-splitbutton.p-button-text > .p-button {
    @apply bg-transparent text-primary-300 border-transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-primary-300 border-transparent;
    background: rgba(165, 180, 252, 0.04);
}
.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-primary-300 border-transparent;
    @apply bg-primary-300 bg-opacity-16;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button {
    @apply text-[rgba(255,255,255,0.60)];
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-[rgba(255,255,255,0.60)];
    @apply bg-opacity-4;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-[rgba(255,255,255,0.60)];
    background: rgba(255, 255, 255, 0.16);
}
.p-splitbutton.p-button-raised {
    @apply shadow-[0px_3px_1px_-2px_rgba(0,0,0,0.2),0px_2px_2px_0px_rgba(0,0,0,0.14),0px_1px_5px_0px_rgba(0,0,0,0.12)];
}
.p-splitbutton.p-button-rounded {
    @apply rounded-[2rem];
}
.p-splitbutton.p-button-rounded > .p-button {
    @apply rounded-[2rem];
}
.p-splitbutton.p-button-sm > .p-button {
    @apply text-sm px-[1.09375rem] py-[0.65625rem];
}
.p-splitbutton.p-button-sm > .p-button .p-button-icon {
    @apply text-sm;
}
.p-splitbutton.p-button-lg > .p-button {
    @apply text-xl px-[1.5625rem] py-[0.9375rem];
}
.p-splitbutton.p-button-lg > .p-button .p-button-icon {
    @apply text-xl;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    @apply bg-transparent text-slate-300 border border-solid;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-slate-300;
    background: rgba(203, 213, 225, 0.04);
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-slate-300;
    background: rgba(203, 213, 225, 0.16);
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button {
    @apply bg-transparent text-slate-300 border-transparent;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-slate-300 border-transparent;
    background: rgba(203, 213, 225, 0.04);
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-slate-300 border-transparent;
    background: rgba(203, 213, 225, 0.16);
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button {
    @apply bg-transparent text-blue-300 border border-solid;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-blue-300;
    background: rgba(147, 197, 253, 0.04);
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-blue-300;
    background: rgba(147, 197, 253, 0.16);
}
.p-splitbutton.p-button-info.p-button-text > .p-button {
    @apply bg-transparent text-blue-300 border-transparent;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-blue-300 border-transparent;
    background: rgba(147, 197, 253, 0.04);
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-blue-300 border-transparent;
    background: rgba(147, 197, 253, 0.16);
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button {
    @apply bg-transparent text-green-300 border border-solid;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-green-300;
    background: rgba(134, 239, 172, 0.04);
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-green-300;
    background: rgba(134, 239, 172, 0.16);
}
.p-splitbutton.p-button-success.p-button-text > .p-button {
    @apply bg-transparent text-green-300 border-transparent;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-green-300 border-transparent;
    background: rgba(134, 239, 172, 0.04);
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-green-300 border-transparent;
    background: rgba(134, 239, 172, 0.16);
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    @apply bg-transparent text-amber-300 border border-solid;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-amber-300;
    background: rgba(252, 211, 77, 0.04);
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-amber-300;
    background: rgba(252, 211, 77, 0.16);
}
.p-splitbutton.p-button-warning.p-button-text > .p-button {
    @apply bg-transparent text-amber-300 border-transparent;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-amber-300 border-transparent;
    background: rgba(252, 211, 77, 0.04);
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-amber-300 border-transparent;
    background: rgba(252, 211, 77, 0.16);
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button {
    @apply bg-transparent text-purple-300 border border-solid;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-purple-300;
    background: rgba(216, 180, 254, 0.04);
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-purple-300;
    background: rgba(216, 180, 254, 0.16);
}
.p-splitbutton.p-button-help.p-button-text > .p-button {
    @apply bg-transparent text-purple-300 border-transparent;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-purple-300 border-transparent;
    background: rgba(216, 180, 254, 0.04);
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-purple-300 border-transparent;
    background: rgba(216, 180, 254, 0.16);
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    @apply bg-transparent text-red-300 border border-solid;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-red-300;
    background: rgba(252, 165, 165, 0.04);
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-red-300;
    background: rgba(252, 165, 165, 0.16);
}
.p-splitbutton.p-button-danger.p-button-text > .p-button {
    @apply bg-transparent text-red-300 border-transparent;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):hover {
    @apply text-red-300 border-transparent;
    background: rgba(252, 165, 165, 0.04);
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text
> .p-button:not(button):not(a):not(.p-disabled):active {
    @apply text-red-300 border-transparent;
    background: rgba(252, 165, 165, 0.16);
}
.p-tree {
    @apply border text-primary p-5 rounded-md border-solid border-primary-950;
    @apply bg-default;
}
.p-tree .p-tree-container .p-treenode {
    @apply p-[0.143rem];
    outline: 0 none;
}
.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    @apply outline-offset-0 shadow-[inset_0_0_0_0.15rem_rgba(165,180,252,0.5)];
    outline: 0 none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
    @apply transition-shadow duration-[0.2s] p-2 rounded-md;
}
/* ALTERADO PELA PGE-RS: retirado mr-2 */
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    @apply w-8 h-8 text-opacity-60 rounded-[50%] border-0 border-none enabled:hover:text-opacity-87 enabled:hover:border-transparent focus-visible:outline-offset-0 focus-visible:shadow-[0_0_0_0.2rem_rgba(165,180,252,0.5)];
    @apply bg-opacity-0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content
.p-tree-toggler:enabled:hover {
    @apply bg-opacity-4;
}
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content
.p-tree-toggler:focus-visible {
    outline: 0 none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    @apply text-[rgba(255,255,255,0.60)] mr-2;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    @apply mr-2;
}
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content
.p-checkbox
.p-indeterminate
.p-checkbox-icon {
    @apply text-opacity-87;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    @apply text-opacity-87 bg-primary-300 opacity-16;
}
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content.p-highlight
.p-tree-toggler,
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content.p-highlight
.p-treenode-icon {
    @apply text-opacity-87;
}
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content.p-highlight
.p-tree-toggler:hover,
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content.p-highlight
.p-treenode-icon:hover {
    @apply text-opacity-87;
}
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    @apply text-opacity-87 bg-opacity-4;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    @apply text-opacity-87 bg-opacity-4;
}
.p-tree .p-tree-filter-container {
    @apply mb-2;
}
.p-tree .p-tree-filter-container .p-tree-filter {
    @apply w-full pr-7;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
    @apply text-[rgba(255,255,255,0.60)] right-3;
}
.p-tree .p-treenode-children {
    @apply pl-4 pr-0 py-0;
}
.p-tree .p-tree-loading-icon {
    @apply text-[2rem];
}
.p-tree .p-tree-loading-icon.p-icon {
    @apply w-8 h-8;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    @apply bg-[rgba(84,113,249,0.16)];
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    @apply border bg-primary-950 text-default text-opacity-87 transition-shadow duration-[0.2s] p-2 rounded-md border-solid border-primary-950 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(165,180,252,0.5)];
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    @apply bg-primary-300 bg-opacity-16 text-opacity-87;
}
.p-tree.p-tree-horizontal
.p-treenode
.p-treenode-content.p-highlight
.p-treenode-icon {
    @apply text-opacity-87;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    @apply mr-2;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    @apply text-opacity-60 mr-2;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    @apply mr-2;
}
.p-tree.p-tree-horizontal
.p-treenode
.p-treenode-content
.p-treenode-label:not(.p-highlight):hover {
    @apply bg-inherit text-inherit;
}
.p-tree.p-tree-horizontal
.p-treenode
.p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    @apply text-opacity-87;
    @apply bg-opacity-4;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
}
.p-checkbox {
    @apply w-[22px] h-[22px];
}
.p-checkbox .p-checkbox-box {
    @apply w-[22px] h-[22px] text-opacity-87 rounded-md border-2 border-solid border-[#0b213f];
    @apply bg-default;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    @apply duration-[0.2s] text-primary-950 text-sm;
}
.p-checkbox .p-checkbox-box .p-icon {
    @apply w-3.5 h-3.5;
}
.p-checkbox .p-checkbox-box.p-highlight {
    @apply border-primary-300 bg-primary-300;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    @apply border-primary-300;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    @apply outline-offset-0 shadow-[0_0_0_0.2rem_rgba(165,180,252,0.5)] border-primary-300;
    outline: 0 none;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    @apply text-primary-950 border-primary-400;
    @apply bg-primary-400;
}
p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    @apply border-red-300;
}
.p-input-filled .p-checkbox .p-checkbox-box {
    @apply bg-[#0b213f];
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    @apply bg-primary-300;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    @apply bg-[#0b213f];
}
.p-input-filled
.p-checkbox:not(.p-checkbox-disabled)
.p-checkbox-box.p-highlight:hover {
    @apply bg-primary-400;
}
.p-checkbox-label {
    @apply ml-2;
}
.p-contextmenu {
    @apply text-opacity-87 border shadow-[0_2px_4px_-1px_rgba(0,0,0,0.2),0_4px_5px_0_rgba(0,0,0,0.14),0_1px_10px_0_rgba(0,0,0,0.12)] w-[12.5rem] px-0 py-1 rounded-md border-solid border-[#0b213f];
    @apply bg-default;
}
.p-contextmenu .p-contextmenu-root-list {
    outline: 0 none;
}
.p-contextmenu .p-submenu-list {
    @apply border shadow-[0_2px_4px_-1px_rgba(0,0,0,0.2),0_4px_5px_0_rgba(0,0,0,0.14),0_1px_10px_0_rgba(0,0,0,0.12)] px-0 py-1 rounded-md border-solid border-[#0b213f];
    @apply bg-default;
}
.p-contextmenu .p-menuitem > .p-menuitem-content {
    @apply text-opacity-87 transition-shadow duration-[0.2s] rounded-none;
}
.p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    @apply text-opacity-87 select-none px-5 py-3;
}
.p-contextmenu
.p-menuitem
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-contextmenu
.p-menuitem
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon {
    @apply text-[rgba(255,255,255,0.60)] mr-2;
}
.p-contextmenu
.p-menuitem
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-[rgba(255,255,255,0.60)];
}
.p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    @apply text-opacity-87;
    @apply bg-primary-300 bg-opacity-16;
}
.p-contextmenu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-contextmenu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon,
.p-contextmenu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    @apply bg-primary-300 bg-opacity-25;
}
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content {
    @apply text-opacity-87;
    @apply bg-default;
}
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon,
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover {
    @apply text-opacity-87;
    @apply bg-opacity-4;
}
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-menuitem-icon,
.p-contextmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-contextmenu .p-menuitem-separator {
    @apply mx-0 my-1 border-t-[#0b213f] border-t border-solid;
}
.p-contextmenu .p-submenu-icon {
    @apply text-sm;
}
.p-contextmenu .p-submenu-icon.p-icon {
    @apply w-3.5 h-3.5;
}
.p-menu {
    @apply text-opacity-87 border w-[12.5rem] px-0 py-1 rounded-md border-solid border-[#0b213f];
    @apply bg-default;
}
.p-menu .p-menuitem > .p-menuitem-content {
    @apply text-opacity-87 transition-shadow duration-[0.2s] rounded-none;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    @apply text-opacity-87 select-none px-5 py-3;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    @apply text-opacity-87;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    @apply text-[rgba(255,255,255,0.60)] mr-2;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    @apply text-[rgba(255,255,255,0.60)];
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    @apply text-opacity-87 bg-opacity-16;
}
.p-menu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-menu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon,
.p-menu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    @apply bg-primary-300 bg-opacity-25;
}
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content {
    @apply text-opacity-87;
    @apply bg-default;
}
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon,
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover {
    @apply text-opacity-87 bg-opacity-4;
}
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-menuitem-icon,
.p-menu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-menu.p-menu-overlay {
    @apply border shadow-[0_2px_4px_-1px_rgba(0,0,0,0.2),0_4px_5px_0_rgba(0,0,0,0.14),0_1px_10px_0_rgba(0,0,0,0.12)] border-solid border-[#0b213f];
    @apply bg-default;
}
.p-menu .p-submenu-header {
    @apply text-opacity-87 font-bold m-0 px-5 py-3 rounded-t-none;
    @apply bg-default;
}
.p-menu .p-menuitem-separator {
    @apply mx-0 my-1 border-t-[#0b213f] border-t border-solid;
}
.p-menu .p-menuitem-badge {
    @apply text-primary-950 text-xs font-bold min-w-[1.5rem] h-6 leading-6 ml-2 px-2 rounded-md;
    @apply bg-primary-300;
}
.p-tieredmenu {
    @apply text-opacity-87 border w-[12.5rem] px-0 py-1 rounded-md border-solid border-[#0b213f];
    @apply bg-default;
}
.p-tieredmenu.p-tieredmenu-overlay {
    @apply border shadow-[0_2px_4px_-1px_rgba(0,0,0,0.2),0_4px_5px_0_rgba(0,0,0,0.14),0_1px_10px_0_rgba(0,0,0,0.12)] border-solid border-[#0b213f];
    @apply bg-default;
}
.p-tieredmenu .p-tieredmenu-root-list {
    outline: 0 none;
}
.p-tieredmenu .p-submenu-list {
    @apply border shadow-[0_2px_4px_-1px_rgba(0,0,0,0.2),0_4px_5px_0_rgba(0,0,0,0.14),0_1px_10px_0_rgba(0,0,0,0.12)] px-0 py-1 border-solid border-[#0b213f];
    @apply bg-default;
}
.p-tieredmenu .p-menuitem > .p-menuitem-content {
    @apply text-opacity-87 transition-shadow duration-[0.2s] rounded-none;
}
.p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    @apply text-opacity-87 select-none px-5 py-3;
}
.p-tieredmenu
.p-menuitem
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-tieredmenu
.p-menuitem
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon {
    @apply text-[rgba(255,255,255,0.60)] mr-2;
}
.p-tieredmenu
.p-menuitem
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-[rgba(255,255,255,0.60)];
}
.p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    @apply text-opacity-87;
    @apply bg-primary-300 bg-opacity-16;
}
.p-tieredmenu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-tieredmenu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon,
.p-tieredmenu
.p-menuitem.p-highlight
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    @apply bg-primary-300 bg-opacity-25;
}
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content {
    @apply text-opacity-87;
    @apply bg-default;
}
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-menuitem-icon,
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
> .p-menuitem-content
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover {
    @apply text-opacity-87;
    @apply bg-opacity-4;
}
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-menuitem-text {
    @apply text-opacity-87;
}
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-menuitem-icon,
.p-tieredmenu
.p-menuitem:not(.p-highlight):not(.p-disabled)
> .p-menuitem-content:hover
.p-menuitem-link
.p-submenu-icon {
    @apply text-opacity-87;
}
.p-tieredmenu .p-menuitem-separator {
    @apply mx-0 my-1 border-t-[#0b213f] border-t border-solid;
}
.p-tieredmenu .p-submenu-icon {
    @apply text-sm;
}
.p-tieredmenu .p-submenu-icon.p-icon {
    @apply w-3.5 h-3.5;
}
.p-overlaypanel {
    @apply text-opacity-87 border shadow-[0px_11px_15px_-7px_rgba(0,0,0,0.2),0px_24px_38px_3px_rgba(0,0,0,0.14),0px_9px_46px_8px_rgba(0,0,0,0.12)] rounded-md border-solid border-[#0b213f] after:border-b-[#071426] after:border-[solid] after:border-[rgba(7,20,38,0)] before:border-b-[#0a1f3c] before:border-[solid] before:border-[rgba(11,33,63,0)];
    @apply bg-default;
}
.p-overlaypanel .p-overlaypanel-content {
    @apply p-5;
}
.p-overlaypanel .p-overlaypanel-close {
    @apply text-primary-950 w-8 h-8 absolute top-[-1rem] right-[-1rem] rounded-[50%] enabled:hover:text-primary-950;
    @apply bg-primary-300;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #91a3fb;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
    @apply border-t-[#071426];
}
.p-overlaypanel.p-overlaypanel-flipped:before {
    @apply border-t-[#0b213f];
}




.p-datatable-table {
    border-collapse: inherit;
}
.p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-datatable-header {
    @apply bg-transparent text-base border border-solid border-[rgba(0,0,0,0.12)];
    border-width: 0 0 1px 0;
    padding: 1.25rem 1.25rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-footer {
    @apply bg-transparent text-base border border-solid;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-thead > tr > th {
    @apply bg-[rgba(0,0,0,0.02)] text-base border border-solid border-b-[rgba(0,0,0,0.12)];
    text-align: left;
    padding: 0.75rem 1.5rem;
    border-width: 0 0 1px 0;
    font-weight: 500;
    transition: none;
  }
  .p-datatable .p-datatable-thead > tr > th:hover {
    @apply bg-[rgba(0,0,0,0.1)];
  }
  .p-datatable .p-datatable-tfoot > tr > td {
    @apply bg-transparent text-base border border-solid;
    text-align: left;
    padding: 1rem 1rem;
    border-width: 0 0 1px 0;
    font-weight: 600;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    @apply text-primary;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    @apply bg-transparent text-primary;
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    @apply text-primary;
  }
  .p-datatable .p-sortable-column.p-highlight {
    @apply bg-[rgba(0,0,0,0.1)];
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    @apply text-base;
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    @apply bg-[rgba(0,0,0,0.1)];
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    @apply text-primary;
  }
  .p-datatable .p-sortable-column:focus-visible {
    @apply shadow-[inset_0_0_0_0.15rem_#dbe2ea];
    outline: 0 none;
  }
  .p-datatable .p-datatable-tbody > tr {
    @apply bg-transparent text-base;
    transition: none;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    @apply border border-solid border-b-[rgba(0,0,0,0.12)];
    text-align: left;
    border-width: 0 0 1px 0;
    padding: 1rem 1.5rem;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    @apply bg-transparent text-base;
    width: 2rem;
    height: 2rem;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    @apply bg-opacity-4 border-transparent text-base;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    @apply shadow-[0_0_0_1px_#dbe2ea];
    outline: 0 none;
    outline-offset: 0;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
  }
  .p-datatable .p-datatable-tbody > tr:focus-visible {
    @apply outline-[solid_0.15rem_#dbe2ea];
    outline-offset: -0.15rem;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    @apply bg-transparent text-base;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    @apply shadow-[inset_0_2px_0_0_#dbe2ea];
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    @apply shadow-[inset_0_-2px_0_0_#dbe2ea];
  }
  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-datatable .p-column-resizer-helper {
    @apply bg-primary-300;
  }
  .p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    @apply bg-card;
  }
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
    @apply bg-card;
  }
  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    @apply bg-[rgba(0,0,0,0.06)];
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    @apply bg-[rgba(0,0,0,0.06)] text-primary-300;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    @apply text-primary-300;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    @apply text-primary-300;
  }
  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.625rem 0.625rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.375rem 0.75rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.75rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.375rem 0.75rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.375rem 0.75rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.5625rem 1.5625rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.9375rem 1.875rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.875rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 0.9375rem 1.875rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 0.9375rem 1.875rem;
  }

  .p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }

  .p-column-filter-menu-button {
    @apply bg-transparent text-primary;
    width: 2rem;
    height: 2rem;
    border: 0 none;
    border-radius: 50%;
    transition: none;
  }
  .p-column-filter-menu-button:hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base border-[transparent];
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-column-filter-menu-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #6366F1;
  }

  .p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #71717A;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-column-filter-clear-button:hover {
    color: #18181B;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-column-filter-clear-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #6366F1;
  }

  .p-column-filter-overlay {
    @apply bg-card text-base;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.25rem 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #6366F1;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid #f3f4f6;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.75rem 1.25rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1.25rem;
  }



  .p-paginator {
    @apply bg-transparent text-base border-solid border-[rgba(0,0,0,0.12)];
    border-width: 0;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
  }
  .p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    @apply bg-transparent text-base border border-solid border-[rgba(0,0,0,0.12)];
    min-width: 3rem;
    height: 3rem;
    margin: 0 0 0 -1px;
    transition: none;
    border-radius: 0;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    @apply bg-[rgba(0,0,0,0.1)] border border-solid text-base;

  }
  .p-paginator .p-paginator-first {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-paginator .p-paginator-last {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 3rem;
  }
  .p-paginator .p-paginator-current {
    @apply bg-[rgba(0,0,0,0.1)] border border-solid text-base border-[rgba(0,0,0,0.12)];
    min-width: 3rem;
    height: 3rem;
    margin: 0 0 0 -1px;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    @apply bg-transparent border border-solid text-base border-[rgba(0,0,0,0.12)];
    min-width: 3rem;
    height: 3rem;
    margin: 0 0 0 -1px;
    transition: none;
    border-radius: 0;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    @apply bg-[rgba(0,0,0,0.02)] border border-solid text-base font-bold border-[rgba(0,0,0,0.2)];
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    @apply bg-[rgba(0,0,0,0.1)] border border-solid text-base border-[rgba(0,0,0,0.4)];
  }
  .p-dropdown {
    @apply bg-transparent border border-solid border-[rgba(0,0,0,0.12)];
    transition: none;
    border-radius: 0.375rem;
  }
  .p-dropdown:not(.p-disabled):hover {
    @appply border-[rgba(0,0,0,0.2)];
  }
  .p-dropdown:not(.p-disabled).p-focus {
    @apply border-primary-300 shadow-[0_0_0_1px_primary-300];
    outline: 0 none;
    outline-offset: 0;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #71717A;
  }
  .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    @apply bg-transparent text-base;
    width: 3rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #71717A;
    right: 3rem;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #f0a9a7;
  }
  .p-dropdown-panel {
    @apply bg-card text-base shadow-[0_0_primary-950,0_0-primary-950,0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-2px_rgba(0,0,0,0.15)];;
    border: 0 none;
    border-radius: 0.375rem;
  }
  .p-dropdown-panel .p-dropdown-header {
    @apply bg-card text-base;
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: #71717A;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0.25rem 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    @apply bg-transparent text-base;
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    transition: none;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    @apply bg-[rgba(0,0,0,0.02)];
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    @apply bg-[rgba(0,0,0,0.02)] text-base;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    @apply bg-[rgba(0,0,0,0.1)] text-base;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    @apply bg-default text-base;
    margin: 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    @apply bg-transparent text-base;
    padding: 0.75rem 1rem;
  }

  .p-inputtext {
    @apply bg-transparent text-base border border-solid;
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    padding: 0.75rem 0.75rem;
    transition: none;
    appearance: none;
    border-radius: 0.375rem;
  }
  .p-inputtext:enabled:hover {
    @apply border border-solid border-primary-300;
  }
  .p-inputtext:enabled:focus {
    @appy border-primary-400 shadow-[0_0_0_1px_rgba(165,180,252,0.7)];;
    outline: 0 none;
    outline-offset: 0;
  }
  .p-inputtext.ng-dirty.ng-invalid {
    border-color: #f0a9a7;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.9375rem 0.9375rem;
  }
@layer primeng {
    .p-button-label {
        @apply font-bold;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }
    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
        @apply z-[1] block absolute h-0.5 transition-[500ms] duration-[cubic-bezier(0.35,0,0.25,1)] bottom-0;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        @apply bg-primary-300;
    }
    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        @apply bg-primary-300;
    }
    .p-button:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(165,180,252,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-button.p-button-secondary:enabled:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(203,213,225,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-button.p-button-success:enabled:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(134,239,172,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-button.p-button-info:enabled:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-button.p-button-warning:enabled:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(252,211,77,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-button.p-button-help:enabled:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(216,180,254,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-button.p-button-danger:enabled:focus {
        @apply shadow-[0_0_0_2px_#1c2127,0_0_0_4px_rgba(252,165,165,0.7),0_1px_2px_0_rgba(0,0,0,0.0)];
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
        @apply shadow-[inset_0_2px_0_0_#a5b4fc];
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
        @apply shadow-[inset_0_-2px_0_0_#a5b4fc];
    }
}
