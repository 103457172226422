/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

/* ----------------------------------------------------------------------------------------------------- */
/*  ESTE ARQUIVO ESTÁ SENDO USADO PELA PGE-RS PARA SOBRESCREVER O CSS DO SUPP.
/* ----------------------------------------------------------------------------------------------------- */

.custom-icon-html {
    background: url('/assets/icons/pge/html.png') no-repeat center;
}

.custom-icon-pdf {
    background: url('/assets/icons/pge/pdf.png') no-repeat center;
}

.custom-icon-doc {
    background: url('/assets/icons/pge/word.png') no-repeat center;
}

.custom-icon-docx {
    background: url('/assets/icons/pge/word.png') no-repeat center;
}

.custom-icon-ods {
    background: url('/assets/icons/pge/ods.png') no-repeat center;
}

.custom-icon-odt {
    background: url('/assets/icons/pge/odt.png') no-repeat center;
}

.custom-icon-xls {
    background: url('/assets/icons/pge/excel.png') no-repeat center;
}

.custom-icon-xlsx {
    background: url('/assets/icons/pge/excel.png') no-repeat center;
}

.custom-icon-pjc {
    background: url('/assets/icons/pge/pjc.png') no-repeat center;
}

.custom-icon-mp4 {
    background: url('/assets/icons/pge/html.mp4') no-repeat center;
}

.custom-icon-p7s {
    background: url('/assets/icons/pge/doc.png') no-repeat center;
}

.custom-icon-default {
    background: url('/assets/icons/pge/doc.png') no-repeat center;
}
  
mat-icon.custom-icon-html,
mat-icon.custom-icon-pdf,
mat-icon.custom-icon-doc,
mat-icon.custom-icon-docx,
mat-icon.custom-icon-xls,
mat-icon.custom-icon-xlsx,
mat-icon.custom-icon-pjc,
mat-icon.custom-icon-ods,
mat-icon.custom-icon-odt,
mat-icon.custom-icon-ods,
mat-icon.custom-icon-mp3,
mat-icon.custom-icon-mp4,
mat-icon.custom-icon-p7s,
mat-icon.custom-icon-default {
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    font-size: 0;
    margin-left: 2px;
}

/* ALTERADO PELA PGE-RS: objetivo é reduzir a altura entre os campos de edição. */
.gap-2 {
    row-gap: 0px !important;
    column-gap: 0px !important;
}

.px-6 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.pge-titulo-tela {
    font-weight: 600;
    color: gray;
    //padding: 8px 0 8px 12px;
    //box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    //border-radius: 6px;
    //margin-right: 8px;
    //width: 100%;
    //background-color: #f2f3f6;
    //@apply dark:bg-accent-700 dark:text-on-accent-700 bg-primary text-on-primary #{'!important'};
}

.pge-titulo-tela2 {
    padding: 10px 8px 8px 16px;
    font-weight: 600;
    border-radius: 8px;
    margin-right: 8px;
    width: 100%;
}

.pge-outlined {
    border: 1px lightgray solid;
    border-radius: 3px;
}

/* Quebra as opções do radio button em linhas. */
.pge-radio-button-options {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 15px 0px;
}

.mat-badge-content {
    background-color: orangered !important;
}

// ALTERADO PELA PGE-RS: deixar mais forte a setinha.
.mat-expansion-indicator {
    &::after {
        border-color: var(--mat-expansion-header-description-color) !important; 
    }
}

// ALTERADO PELA PGE-RS: padronizar os expansion-panel.
.mat-expansion-panel {
    padding: 0px !important;
    border: 1px #ddd solid !important;
    border-radius: 8px !important;
    margin: 2px !important;
    background: var(--mat-expansion-container-background-color) !important;
    box-shadow: none !important;
}

/* Alternar a cor na lista de tarefas. 
.drag-tarefa:nth-child(odd) {
    background-color: var(--cdk-divider);
}
.drag-tarefa:nth-child(even) {
   // background-color: var(--cdk-bg-card);
}
*/

.host-class {
    width: 100%; 
}

/* Substitui a sombra da linha superior por uma linha mais simples. */
.mat-toolbar {
    border-bottom: 1px lightgray solid !important;
    box-shadow: none !important;
}

/* Aumentando a altura dos divs que abrem para escolher as colunas da grid. */
div.mat-mdc-select-panel {
    max-height: 500px !important; 
}

/* Aumentando a largura dos divs que abrem para escolher as colunas da grid. */
.mat-mdc-form-field-infix {
    width: 240px !important;
}

/* Aumentando a altura dos divs que abrem para escolher as colunas da list. */
.h-256 {
    height: auto !important;
    max-height: 500px !important; 
}

.scale-75 {
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
}

/* Aumentando os ícones pequenaos tipo o da lupa e copiar. */
.w-12 {
    font-size: clamp(14px, 14px, 20px) !important;
}

/* Dando ênfase ao passar o mouse por cima de ícones. */
.mat-icon:hover {
    transform: scale(1.15);
}

/* Dando ênfase ao passar o mouse por cima de ícones. */
.mat-icon-tarefa-list-item {
    font-size: 20px !important;
}

/* Dando ênfase ao passar o mouse por cima de ícones. */
.mat-icon-tarefa-list-item:hover {
    transform: scale(1.3);
}

/* Melhorando a distância das etiquetas. */
.mdc-evolution-chip-set .mdc-evolution-chip {
    margin: 0 4px 0 0 !important;
}
.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    margin-left: 0px !important;
}
input.mat-mdc-chip-input {
    margin-left: 0px !important;
}

/* COMENTADO POIS IMPACTOU NO GERENCIAMENTO DE ETIQUETAS.
.mdc-evolution-chip__checkmark {
    display: none !important;
}
*/

.p-treenode {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.p-treenode-content {
    padding: 0 0 0 8px !important;
}

mat-hint {
    font-size: 0.7em !important;
}