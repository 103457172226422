/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import 'quill/dist/quill.snow.css';

@import '../@cdk/styles/components/_prism';
@import '../@cdk/styles/components/_ngx-color-picker';

// Ag-Grid Component
// Utilizado no módulo do cálculo
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

// primeng.org/installation
// Themes from Supp for Primeng
@import "themes/primeng-minimal.css";
@import "primeng/resources/primeng.css";

.apexcharts-tooltip {
    @apply dark:bg-dialog dark:text-default #{'!important'};
}

.apexcharts-tooltip-title {
    @apply dark:text-default dark:bg-default #{'!important'};
}

.apexcharts-xaxis-label {
    @apply dark:text-primary #{'!important'};
}

.apexcharts-yaxis-label {
    @apply dark:text-primary #{'!important'};
}

div.p-overlaypanel {
    z-index: 50 !important;
}

iframe {
    isolation: isolate;
    background-color: #FFF !important;
    padding: 4px;
}
